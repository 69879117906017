import React from 'react';
import Link from "gatsby-link";

function Casestudylist({data}) {
    return (
        <>

            <div className="main_listing active">
                <h4>Case Studies</h4>
                <ul>
                    {data && data ? data.map((item, i) => (
                        <>
                            {item.status !== 0 ?
                                <li key={i}>
                                    <Link to={`/profile/${item.slug}`}>{item.name}</Link>
                                </li>
                                : ''}
                        </>

                    )) : ''}
                </ul>
            </div>
        </>
    );
}

export default Casestudylist;